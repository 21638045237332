import React from "react"
import Select, { components } from "react-select"
import DefaultTooltip from "../../../Common/Tooltip"
import { CheckboxInput } from "../../../Common/CommonElements"
import { Cross, FiltersDropdown, DefaultProfilePicture } from "../../../../assets/images"

const initialize = (name, state, setState) => {
  if (!state[name]) {
    setState((prev) => ({ ...prev, [name]: null }))
  }
}

const clearState = (name, setState) => {
  setState((prev) => ({ ...prev, [name]: null }))
}

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <img src={FiltersDropdown} alt="" />
  </components.DropdownIndicator>
)

const Option = (props) => (
  <components.Option {...props}>
    <CheckboxInput type="checkbox" value={props.isSelected} onChange={() => null} color="#000000" />
    <label>{props.label}</label>
  </components.Option>
)

const SearchBarOption = (props) => (
  <components.Option {...props}>
    <div className="search-option">
      <img src={props.data.picture || DefaultProfilePicture} alt="" className="image" />
      <div className="username">@{props.label}</div>|<div className="followers">{props.data.followers || "N/A"} followers</div>
    </div>
  </components.Option>
)

const WeightSelector = ({ value, isMulti, name, weights, state, setState, clearValue }) => {
  const handleWeightChange = (option) => {
    setState((prev) => ({
      ...prev,
      [name]: isMulti ? prev[name].map((item) => (item.value === value.value ? { ...item, weight: option.value } : item)) : { ...prev[name], weight: option.value },
    }))
  }

  const selectedWeight = isMulti ? weights.find((item) => item.value === value.weight) : weights.find((item) => item.value === state[name]?.weight)

  return (
    <div className="options-container">
      <div className="flex-container">
        {isMulti && (
          <div className="clear-button" onClick={() => clearValue(value)}>
            <img src={Cross} className="cross-icon" alt="" />
          </div>
        )}
        <div className="option-text">{value.label}</div>
      </div>
      <div className="weight-filter-container">
        <Select classNamePrefix="react-select" placeholder="Select..." options={weights} value={selectedWeight || weights[0]} onChange={handleWeightChange} components={{ DropdownIndicator }} />
      </div>
    </div>
  )
}

const FilterContainer = ({ name, label, tooltip, state, setState, children, hideHeader }) => (
  <div className="filter-container">
    {!hideHeader && (
      <div className="flex-container">
        <div className="label">{label}</div>
        {tooltip && <DefaultTooltip content={tooltip} />}
        {state[name] && (Array.isArray(state[name]) ? state[name].length > 0 : true) && (
          <div className="clear-button" onClick={() => clearState(name, setState)}>
            Clear
          </div>
        )}
      </div>
    )}
    <>{children}</>
  </div>
)

export { initialize, clearState, DropdownIndicator, Option, WeightSelector, FilterContainer, SearchBarOption }
