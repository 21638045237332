import React, { useState, useEffect } from "react"
import { Button } from "../../../Common/CommonElements"
import DefaultTooltip from "../../../Common/Tooltip"
import { isObjectEmpty } from "../../../../helpers"
import "../styles.scss"
import { InstagramActive, InstagramInactive, YoutubeActive, YoutubeInactive, TiktokActive, TiktokInactive } from "../../../../assets/images"

const PlayCard = ({ title, description, image, overlay, platforms, buttonText, callback, children, type, defaultFilters }) => {
  const [currentPlatform, setCurrentPlatform] = useState(platforms[0])
  const [filters, setFilters] = useState({})

  useEffect(() => {
    defaultFilters && setFilters(defaultFilters)
  }, [])

  const handlePlayClick = () => {
    !isObjectEmpty(filters) && callback(currentPlatform, filters, type, title)
  }

  const platformIcons = {
    instagram: { active: InstagramActive, inactive: InstagramInactive },
    youtube: { active: YoutubeActive, inactive: YoutubeInactive },
    tiktok: { active: TiktokActive, inactive: TiktokInactive },
  }

  return (
    <div className="PlayCard">
      <div className="PlayCard__image-container">
        <img src={image} alt="play" className="PlayCard__image" />
        <img src={overlay} alt="play" className="PlayCard__image-overlay" />
      </div>
      <div className="PlayCard__title">{title}</div>
      <div className="PlayCard__description">{description}</div>
      <div className="PlayCard__platforms-parent-container">
        <div className="PlayCard__platforms-container">
          {platforms.map((platform) => (
            <div key={platform} className={`PlayCard__platform-switch ${currentPlatform === platform ? "PlayCard__platform-switch--active" : ""}`} onClick={() => setCurrentPlatform(platform)}>
              <img src={currentPlatform === platform ? platformIcons[platform].active : platformIcons[platform].inactive} className="PlayCard__platform-icon" alt={platform} />
            </div>
          ))}
        </div>
        <DefaultTooltip content="Choose the social platform to perform your search" />
      </div>
      <div className="PlayCard__filters-container">{React.Children.map(children, (child) => React.cloneElement(child, { platform: currentPlatform, state: filters, setState: setFilters }))}</div>
      <Button type="button" label={buttonText} theme={{ backgroundColor: "#94a4ff", color: "#ffffff", padding: "0.1rem 1rem", fontSize: "14px" }} onClick={handlePlayClick} />
    </div>
  )
}

export default PlayCard
