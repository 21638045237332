import React, { useState, useEffect, useRef } from "react"
import PlayCard from "./PlayCard"
import { Dialog, DialogTitle, DialogContent, Tab } from "@mui/material"
import { TabList, TabPanel, TabContext } from "@mui/lab"
import { SelectFilter, RangeFilter, TextFilter } from "../../Filters"
import { platforms, numberRange, audienceGenderOptions, weightOptions, audienceAgeOptions, interestsOptions } from "../../../../constants"
import "../styles.scss"
import {
  AdvancedSearch,
  CompetitorAnalysis,
  AudienceTargeting,
  UgcPlay,
  GrowthPlay,
  Cross,
  BackButton,
  RedStars,
  LookalikePlayMain,
  LookalikePlayOverlay,
  EngagementPlayMain,
  EngagementPlayOverlay,
  ProfessionPlayMain,
  ProfessionPlayOverlay,
  BioPlayMain,
  BioPlayOverlay,
  HashtagPlayMain,
  HashtagPlayOverlay,
  TopicsPlayMain,
  TopicsPlayOverlay,
  CompPlayMain,
  CompPlayOverlay,
  AffiliationPlayMain,
  AffiliationPlayOverlay,
  RisingStarsPlayMain,
  RisingStarsPlayOverlay,
  OrganicUgcPlayMain,
  OrganicUgcPlayOverlay,
  UgcDiscoveryPlayMain,
  UgcDiscoveryPlayOverlay,
  RIghtAudiencePlayMain,
  RIghtAudiencePlayOverlay,
  LocationPlayMain,
  LocationPlayOverlay,
  AgePlayMain,
  AgePlayOverlay,
  GenderPlayMain,
  GenderPlayOverlay,
  LanguagePlayMain,
  LanguagePlayOverlay,
  ArrownGif,
} from "../../../../assets/images"

const PlaybookModal = ({ username, modalIsOpen, closeModal, setCurrentPlatform, influencerService, setInfluencerFilters, setAudienceFilters, runPlaybookFilters }) => {
  const [currentTab, setCurrentTab] = useState()
  const [currentPlay, setCurrentPlay] = useState()
  const [showScrollIndicator, setShowScrollIndicator] = useState(true)

  const modalRef = useRef(null)

  const selectPlay = (play) => {
    setCurrentTab(play)
  }

  const handlePlayChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const submitFilters = (platform, filters, type, playName) => {
    type === "influencer" ? setInfluencerFilters(filters) : setAudienceFilters(filters)
    setCurrentPlatform(platform)
    setCurrentPlay(playName)
    closeModal()
  }

  const scrollEvent = (e) => {
    const bottom = e.target.scrollTop < e.target.scrollHeight - e.target.clientHeight - 50
    if (!bottom) {
      setShowScrollIndicator(false)
    } else {
      setShowScrollIndicator(true)
    }
  }

  const handleScroll = () => {
    if (modalRef.current) {
      modalRef.current.scrollBy({ top: 700, behavior: "smooth" })
    }
  }

  useEffect(() => {
    if (currentPlay) {
      runPlaybookFilters(currentPlay)
      setCurrentPlay(null)
    }
  }, [currentPlay])

  return (
    <Dialog open={modalIsOpen} onClose={closeModal} className="PlaybookModal">
      <TabContext value={currentTab}>
        <DialogTitle>
          <div className="PlaybookModal__header">
            <div className="PlaybookModal__header-close-btn" onClick={closeModal}>
              <img src={Cross} alt="Cross" className="PlaybookModal__header-btn-image" />
            </div>
            {currentTab && (
              <div className="PlaybookModal__header-back-btn" onClick={() => selectPlay(null)}>
                <img src={BackButton} alt="BackButton" className="PlaybookModal__header-btn-image" />
              </div>
            )}
            <div className="PlaybookModal__header-title">
              <div className="PlaybookModal__header-title-img">
                <img src={RedStars} alt="RedStarsImage" />
              </div>
              {!currentTab ? (
                <>
                  Hi <span className="PlaybookModal__header-title-username">{username}</span>, here are some tips for you to find influencers quickly
                </>
              ) : (
                <>Click on a card to apply filters quickly</>
              )}
            </div>
          </div>
          {currentTab && (
            <div className="PlaybookModal__tabs-header">
              <TabList onChange={handlePlayChange} className="PlaybookModal__tab-list">
                <Tab label="Advanced Search" value="advancedSearchPlay" className="PlaybookModal__tab-switch" />
                <Tab label="Competitor Analysis" value="competitorAnalysisPlay" className="PlaybookModal__tab-switch" />
                <Tab label="Growth Plays" value="growthPlay" className="PlaybookModal__tab-switch" />
                <Tab label="UGC Plays" value="ugcPlay" className="PlaybookModal__tab-switch" />
                <Tab label="Audience Targeting" value="audienceTargetPlay" className="PlaybookModal__tab-switch" />
              </TabList>
            </div>
          )}
        </DialogTitle>
        <DialogContent ref={modalRef} onScroll={scrollEvent}>
          {!currentTab && (
            <div className="PlaybookModal__plays-cards-container">
              <div className="PlaybookModal__plays-cards--row">
                <div className="PlaybookModal__plays-card PlaybookModal__plays-card--column" onClick={() => selectPlay("advancedSearchPlay")}>
                  <div className="PlaybookModal__plays-card-image" style={{ width: "225px", height: "210px" }}>
                    <img src={AdvancedSearch} alt="AdvancedSearch" className="PlaybookModal__plays-card-image-object" />
                  </div>
                  <div>
                    <div className="PlaybookModal__plays-card-title">Advanced Search</div>
                    <div className="PlaybookModal__plays-card-description">Discover strategies to harness the power of user-generated content.</div>
                  </div>
                </div>
                <div className="PlaybookModal__plays-cards--column">
                  <div className="PlaybookModal__plays-card PlaybookModal__plays-card--row" onClick={() => selectPlay("competitorAnalysisPlay")}>
                    <div className="PlaybookModal__plays-card-image" style={{ width: "140px", height: "120px" }}>
                      <img src={CompetitorAnalysis} alt="CompetitorAnalysis" style={{ width: "15=40px", height: "120px" }} />
                    </div>
                    <div>
                      <div className="PlaybookModal__plays-card-title">Competitor Analysis</div>
                      <div className="PlaybookModal__plays-card-description">Analyze your competitors' influencer strategies and identify opportunities to improve your own approach.</div>
                    </div>
                  </div>
                  <div className="PlaybookModal__plays-card PlaybookModal__plays-card--row" onClick={() => selectPlay("audienceTargetPlay")}>
                    <div className="PlaybookModal__plays-card-image" style={{ width: "150px", height: "150px" }}>
                      <img src={AudienceTargeting} alt="AudienceTargeting" style={{ width: "150px", height: "150px" }} />
                    </div>
                    <div>
                      <div className="PlaybookModal__plays-card-title">Audience Targeting</div>
                      <div className="PlaybookModal__plays-card-description">
                        Reach the right people by targeting influencers with specific audience demographics like location, age, gender, and language.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="PlaybookModal__plays-cards--row">
                <div className="PlaybookModal__plays-card PlaybookModal__plays-card--column" onClick={() => selectPlay("ugcPlay")}>
                  <div className="PlaybookModal__plays-card-image" style={{ width: "160px", height: "130px" }}>
                    <img src={UgcPlay} alt="UgcPlay" className="PlaybookModal__plays-card-image-object" />
                  </div>
                  <div>
                    <div className="PlaybookModal__plays-card-title">UGC Plays</div>
                    <div className="PlaybookModal__plays-card-description">Discover creators who specialize in authentic, user-generated content (UGC) to boost organic engagement.</div>
                  </div>
                </div>
                <div className="PlaybookModal__plays-card PlaybookModal__plays-card--column" onClick={() => selectPlay("growthPlay")}>
                  <div className="PlaybookModal__plays-card-image" style={{ width: "150px", height: "150px" }}>
                    <img src={GrowthPlay} alt="GrowthPlay" className="PlaybookModal__plays-card-image-object" />
                  </div>
                  <div>
                    <div className="PlaybookModal__plays-card-title">Growth Plays</div>
                    <div className="PlaybookModal__plays-card-description">Find and collaborate with rising influencers before they go mainstream.</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <TabPanel value="advancedSearchPlay" className="PlaybookModal__tab-panel">
            <PlayCard
              title="Lookalike Play"
              description="Select your favourite influencer, and we'll find similar ones, big or small, to fit your budget."
              platforms={platforms}
              buttonText="Find Similar Influencers"
              image={LookalikePlayMain}
              overlay={LookalikePlayOverlay}
              callback={submitFilters}
              type="influencer">
              <RangeFilter name="followers" label="Choose follower range" fromOptions={numberRange.from} toOptions={numberRange.to} />
              <SelectFilter
                name="lookalikes"
                isMulti
                isSearch
                isAsync
                service={influencerService.searchInfluencers}
                limit={3}
                placeholder={{
                  instagram: "Insert the handle of your favorite influencer",
                  youtube: "Insert the username of your favorite influencer",
                  tiktok: "Insert the channel of your favorite influencer",
                }}
                hideHeader
              />
            </PlayCard>
            <PlayCard
              title="Engagement Play"
              description="Target high-engagement influencers in your chosen country for exceptional campaign results."
              platforms={platforms}
              buttonText="Find Top Influencers"
              image={EngagementPlayMain}
              overlay={EngagementPlayOverlay}
              callback={submitFilters}
              type="influencer"
              defaultFilters={{ engagementRate: { label: "≥6%", value: 0.06 } }}>
              <RangeFilter name="followers" label="Choose follower range" fromOptions={numberRange.from} toOptions={numberRange.to} />
              <SelectFilter
                name="location"
                isAsync
                isMulti
                limit={3}
                service={influencerService.filterLocations}
                placeholder={{ instagram: "Location of Influencer (City or Country)", youtube: "Location of Influencer (Country)", tiktok: "Location of Influencer (Country)" }}
                hideHeader
              />
            </PlayCard>
            <PlayCard
              title="Profession Search"
              description="Find influencers based on their profession, like “videographers” or “model”, to match your campaign needs."
              platforms={platforms}
              buttonText="Search Profession"
              image={ProfessionPlayMain}
              overlay={ProfessionPlayOverlay}
              callback={submitFilters}
              type="influencer">
              <TextFilter name="bio" placeholder="Insert Profession" hideHeader />
            </PlayCard>
            <PlayCard
              title="Bio Keyword Search"
              description="Search for influencers using specific keywords in their bio, like 'Yoga' 'travel', or 'skincare'."
              platforms={platforms}
              buttonText="Search Bio"
              image={BioPlayMain}
              overlay={BioPlayOverlay}
              callback={submitFilters}
              type="influencer">
              <TextFilter name="bio" placeholder="Insert Bio Keyword" hideHeader />
            </PlayCard>
            <PlayCard
              title="Hashtag Play"
              description="Search broad hashtags, filter by location, and find influencers who’ve used your tags. Expect more results than standard filters."
              platforms={[platforms[0], platforms[2]]}
              buttonText="Search by Hashtag"
              image={HashtagPlayMain}
              overlay={HashtagPlayOverlay}
              callback={submitFilters}
              type="influencer">
              <RangeFilter name="followers" label="Choose follower range" fromOptions={numberRange.from} toOptions={numberRange.to} />
              <SelectFilter name="hashtags" isMulti isCreatable service={influencerService.filterHashtags} limit={3} placeholder="Insert Hashtags" hideHeader />
            </PlayCard>
            <PlayCard
              title="Topics Play"
              description="We’ve curated top influencers for your topic. Start with a broad search term, then narrow down by location."
              platforms={platforms}
              buttonText="Search by Topic"
              image={TopicsPlayMain}
              overlay={TopicsPlayOverlay}
              callback={submitFilters}
              type="influencer">
              <RangeFilter name="followers" label="Choose follower range" fromOptions={numberRange.from} toOptions={numberRange.to} />
              <SelectFilter name="topics" isMulti isCreatable service={influencerService.filterTopics} limit={3} placeholder="Insert Topic" hideHeader />
            </PlayCard>
          </TabPanel>
          <TabPanel value="competitorAnalysisPlay" className="PlaybookModal__tab-panel">
            <PlayCard
              title="Competitor Analysis"
              description="Explore detailed metrics of your competitors, including influential followers and audience demographics like location, gender, and age."
              platforms={platforms}
              buttonText="Analyze Competitor"
              image={CompPlayMain}
              overlay={CompPlayOverlay}
              callback={submitFilters}
              type="influencer">
              <SelectFilter
                name="lookalikes"
                isMulti
                isSearch
                isAsync
                service={influencerService.searchInfluencers}
                limit={3}
                placeholder={{
                  instagram: "Insert the handle of your competitor",
                  youtube: "Insert the TikTok username of your competitor",
                  tiktok: "Insert the YouTube channel of your competitor",
                }}
                hideHeader
              />
            </PlayCard>
            <PlayCard
              title="Influencer Affiliations"
              description="Identify influencers who follow, have worked with, or are affiliated with your competitors. Use this to shape your strategy."
              platforms={[platforms[0], platforms[2]]}
              buttonText="Competitor Affiliations"
              image={AffiliationPlayMain}
              overlay={AffiliationPlayOverlay}
              callback={submitFilters}
              type="influencer">
              <SelectFilter
                name="mentions"
                isMulti
                isSearch
                isAsync
                hideHeader
                service={influencerService.searchInfluencers}
                limit={3}
                placeholder={{
                  instagram: "Insert the handle of your competitor",
                  youtube: "Insert the TikTok username of your competitor",
                  tiktok: "Insert the YouTube channel of your competitor",
                }}
              />
            </PlayCard>
          </TabPanel>
          <TabPanel value="growthPlay" className="PlaybookModal__tab-panel PlaybookModal__tab-panel-single-item">
            <PlayCard
              title="Rising Stars"
              description="This search identifies influencers who have doubled their audience in the last 6 months. Choose a follower range to discover rising influencers in your target regions."
              platforms={platforms}
              buttonText="Find Rising Stars"
              image={RisingStarsPlayMain}
              overlay={RisingStarsPlayOverlay}
              callback={submitFilters}
              type="influencer"
              defaultFilters={{ followersGrowthRate: { label: "6 months", value: "i6months", weight: 1 } }}>
              <RangeFilter name="followers" label="Choose follower range" fromOptions={numberRange.from} toOptions={numberRange.to} />
              <SelectFilter
                name="location"
                isAsync
                isMulti
                limit={3}
                service={influencerService.filterLocations}
                hideHeader
                placeholder={{ instagram: "Location of Influencer (City or Country)", youtube: "Location of Influencer (Country)", tiktok: "Location of Influencer (Country)" }}
              />
            </PlayCard>
          </TabPanel>
          <TabPanel value="ugcPlay" className="PlaybookModal__tab-panel">
            <PlayCard
              title="Organic UGC"
              description="Find influencers who already engage with your brand. Ideal for authentic UGC collaborations with genuine fans."
              platforms={[platforms[0], platforms[2]]}
              buttonText="Find My UGC Creators"
              image={OrganicUgcPlayMain}
              overlay={OrganicUgcPlayOverlay}
              callback={submitFilters}
              type="influencer">
              <SelectFilter
                name="mentions"
                isMulti
                isSearch
                isAsync
                hideHeader
                service={influencerService.searchInfluencers}
                limit={3}
                placeholder={{
                  instagram: "Insert Instagram Handle of Your Brand",
                  youtube: "Insert TikTok Username of Your Brand",
                  tiktok: "Enter the TikTok username of your brand",
                }}
              />
            </PlayCard>
            <PlayCard
              title="UGC Discovery"
              description="Discover influencers who specialize in user generated content, as indicated in their bio. Perfect for expanding your UGC network."
              platforms={[platforms[0], platforms[2]]}
              buttonText="Find My UGC Creators"
              image={UgcDiscoveryPlayMain}
              overlay={UgcDiscoveryPlayOverlay}
              callback={submitFilters}
              type="influencer"
              defaultFilters={{ bio: "ugc" }}></PlayCard>
          </TabPanel>
          <TabPanel value="audienceTargetPlay" className="PlaybookModal__tab-panel">
            <PlayCard
              title="Find the Right Audience"
              description="Focus on influencers whose audience interests align with your brand, such as 'Sports' or 'Coffee & Beverages', to target the right market."
              platforms={[platforms[0]]}
              buttonText="Find Audience"
              image={RIghtAudiencePlayMain}
              overlay={RIghtAudiencePlayOverlay}
              callback={submitFilters}
              type="audience">
              <SelectFilter name="interests" isMulti options={interestsOptions} hideHeader placeholder="Select Interest" defaultWeight={0.45} />
            </PlayCard>
            <PlayCard
              title="Location Play"
              description="Narrow your search to influencers whose audience is in specific locations, even down to the city level for Instagram."
              platforms={platforms}
              buttonText="Find by Location"
              image={LocationPlayMain}
              overlay={LocationPlayOverlay}
              callback={submitFilters}
              type="audience">
              <SelectFilter
                name="location"
                isAsync
                isMulti
                limit={3}
                service={influencerService.filterLocations}
                hideHeader
                placeholder={{ instagram: "Location of Influencer (City or Country)", youtube: "Location of Influencer (Country)", tiktok: "Location of Influencer (Country)" }}
                defaultWeight={0.45}
              />
            </PlayCard>
            <PlayCard
              title="Age & Location Targeting"
              description="Reach influencers whose audiences match specific age groups and locations to maximize relevance and impact for your campaign."
              platforms={platforms}
              buttonText="Find Audience"
              image={AgePlayMain}
              overlay={AgePlayOverlay}
              callback={submitFilters}
              type="audience">
              <SelectFilter
                name="location"
                isAsync
                isMulti
                limit={3}
                service={influencerService.filterLocations}
                hideHeader
                placeholder={{ instagram: "Location of Influencer (City or Country)", youtube: "Location of Influencer (Country)", tiktok: "Location of Influencer (Country)" }}
              />
              <SelectFilter name="age" isMulti options={audienceAgeOptions} weights={weightOptions} placeholder="Select age" hideHeader />
            </PlayCard>
            <PlayCard
              title="Gender & Location Targeting"
              description="Target influencers whose audiences are predominantly of a specific gender in key locations, perfect for precise, gender-focused campaigns."
              platforms={platforms}
              buttonText="Find by Gender"
              image={GenderPlayMain}
              overlay={GenderPlayOverlay}
              callback={submitFilters}
              type="audience">
              <SelectFilter
                name="location"
                isAsync
                isMulti
                limit={3}
                service={influencerService.filterLocations}
                hideHeader
                placeholder={{ instagram: "Location of Influencer (City or Country)", youtube: "Location of Influencer (Country)", tiktok: "Location of Influencer (Country)" }}
              />
              <SelectFilter name="gender" options={audienceGenderOptions} weights={weightOptions} placeholder="Select gender" hideHeader />
            </PlayCard>
            <PlayCard
              title="Language of Audience"
              description="Find influencers whose audiences speak your target language, making sure your message connects culturally and effectively."
              platforms={platforms}
              buttonText="Find Audience Language"
              image={LanguagePlayMain}
              overlay={LanguagePlayOverlay}
              callback={submitFilters}
              type="audience">
              <SelectFilter name="language" label="Language" isAsync service={influencerService.filterLanguages} weights={weightOptions} placeholder="Insert Language" hideHeader />
            </PlayCard>
          </TabPanel>
          {currentTab && showScrollIndicator && (
            <div className="PlaybookModal__scroll-indicator" onClick={handleScroll}>
              <img src={ArrownGif} alt="" priority loading="eager" />
            </div>
          )}
        </DialogContent>
      </TabContext>
    </Dialog>
  )
}

export default PlaybookModal
