import React from "react"
import { useNavigate } from "react-router-dom"
import { useGoogleLogin } from "@react-oauth/google"
import useAuthService from "../../../services/useAuthService"
import GoogleIcon from "../../../assets/images/google.svg"
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"
import "./styles.scss"

const GoogleAuth = () => {
  const navigate = useNavigate()
  const authService = useAuthService()

  const { getData } = useVisitorData({ extendedResult: true })

  const callback = useGoogleLogin({
    onSuccess: async (res) => {
      const params = { token: res.access_token, provider: "google" }
      authService.socialAuthentication(params).then((data) => {
        if (data && !data.role) {
          navigate("/set_role")
        } else if (data && data.role) {
          navigate(`/${data.role}/dashboard`)
        }

        getData()
          .then((res) => {
            authService.addFingerprint(data.id, res)
          })
          .catch((e) => {})
      })
    },
    onFailure: () => {},
  })

  return (
    <button className="social-auth-button" onClick={callback} type="button">
      <img src={GoogleIcon} className="icon" alt="google" />
      <span className="text">Continue with Google</span>
    </button>
  )
}

export default GoogleAuth
