import React, { useState, useEffect } from "react"
import { Img } from "react-image"
import { useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import useAuthService from "../../../services/useAuthService"
import Skeleton from "@mui/material/Skeleton"
import { useMediaQuery } from "react-responsive"
import "./styles.scss"
import Logo from "../../../assets/images/logo.svg"
import DefaultProfilePicture from "../../../assets/images/default-profile-picture.svg"
import ArrowDown from "../../../assets/images/arrow-down.svg"

const Header = () => {
  const navigate = useNavigate()
  const authService = useAuthService()
  const location = useLocation()
  const currentUser = useSelector((state) => state.auth?.user)
  const credits = useSelector((state) => state.auth?.credits)
  const [avatarImage, setAvatarImage] = useState(currentUser?.profile_picture_url || DefaultProfilePicture)
  const isDashboard = location.pathname === "/brand/dashboard"

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1214px)" })

  useEffect(() => {
    setAvatarImage(currentUser?.profile_picture_url || DefaultProfilePicture)
  }, [currentUser])

  const handleLogout = async () => {
    authService.logout().then((data) => {
      navigate("/login")
    })
  }

  const handleClick = async (hash) => {
    if (!isDashboard) {
      await navigate("/brand/dashboard")
    }
    const button = document.querySelector(`.header-content .nav-container .nav-link[data-bs-target="${hash}"]`)
    if (button) {
      button.click()
    }
  }

  return (
    <>
      {isSmallScreen && (
        <div className="mobile-warning-banner">
          <div className="heading">
            Sorry, we are not attractive on this screen size !!! <br />
            Please use the application on a larger screen for a better experience
          </div>
        </div>
      )}
      <header>
        <div className="header-content">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="logo-container" onClick={() => handleClick("#find-influencers-page")}>
                <img src={Logo} className="logo" alt="logo" />
              </div>
              <div className="nav-container">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button className={`nav-link ${isDashboard ? "active" : ""}`} data-bs-toggle="tab" data-bs-target="#find-influencers-page" onClick={() => handleClick("#find-influencers-page")}>
                      Find Influencers
                    </button>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#manage-influencers-page" onClick={() => handleClick("#manage-influencers-page")}>
                      Manage Influencers
                    </button>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#boards-page" onClick={() => handleClick("#boards-page")}>
                      Boards
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="results-container">
                <div className="result mx-2">
                  <span className="heading">Remaining credits: </span>
                  <span className="value">{credits}</span>
                </div>
                <button className="btn ms-2" onClick={() => navigate("/brand/plans")}>
                  Upgrade
                </button>
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center">
                <div className="avatar-container">
                  <Img src={avatarImage} className="avatar" loader={<Skeleton variant="rounded" width={150} height={150} />} />
                </div>
                <div className="profile-info">
                  <div className="name">{currentUser?.name}</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="email">{currentUser?.email}</div>
                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                      <div className="icon-container">
                        <img src={ArrowDown} className="icon" alt="" />
                      </div>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item mb-1" href="/brand/account">
                          Account
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item mb-1" href="/brand/account?activeTab=billing">
                          Billing
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item mb-1" href="/brand/account?activeTab=payment">
                          Payment Methods
                        </a>
                      </li>
                      <li>
                        <div className="dropdown-item text-danger" onClick={handleLogout}>
                          Log out
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
