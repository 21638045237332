import { useEffect } from "react"
import { useSelector } from "react-redux"
import Intercom from "@intercom/messenger-js-sdk"

const IntercomComponent = () => {
  const currentUser = useSelector((state) => state.auth?.user)

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      Intercom({
        app_id: "nb2w88mv",
        user_id: currentUser?.id,
        name: currentUser?.name,
        email: currentUser?.email,
        created_at: currentUser?.created_at,
      })
    }
  }, [currentUser])

  return null
}

export default IntercomComponent
