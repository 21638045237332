import React from "react"
import { Img } from "react-image"
import Skeleton from "@mui/material/Skeleton"
import PeopleIcon from "@mui/icons-material/People"
import DefaultProfilePicture from "../../../assets/images/default-profile-picture.svg"
import "./styles.scss"

const Audience = ({ title, users }) => {
  return (
    <div className="users-container">
      {title && (
        <div className="heading">
          <PeopleIcon /> {title}
        </div>
      )}
      {users?.map((user) => (
        <div key={user.id} className="user">
          <div className="profile-container">
            <div className="image-container">
              <Img src={user.picture} className="image" loader={<Skeleton variant="circular" width={50} height={50} />} onError={(e) => (e.target.src = DefaultProfilePicture)} />
            </div>
            <a href={user.profile} target="_blank" className="name" rel="noreferrer">
              {user.username || user.fullname}
            </a>
          </div>
          <div className="audience-metrics-container">
            <div className="metric">
              <div className="number">{user.followers}</div>
              <div className="text">Followers</div>
            </div>
            <div className="metric">
              <div className="number">{user.engagement_rate}</div>
              <div className="text">Engagement Rate</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Audience
