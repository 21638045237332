import React from "react"
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const LineChart = ({ title, labels, values }) => {
  const formatYAxisLabel = (value) =>
    value >= 1_000_000_000 ? `${value / 1_000_000_000}b` : value >= 1_000_000 ? `${value / 1_000_000}m` : value >= 1000 ? `${value / 1000}k` : value % 1 === 0 ? value.toString() : value.toFixed(2)

  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: "#94A4FF",
        hoverBackgroundColor: "#94A4FF",
        borderColor: "#94A4FF",
        data: values,
      },
    ],
  }

  const options = {
    indexAxis: "x",
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      y: {
        beginAtZero: false,
        grid: {
          display: false,
        },
        ticks: {
          callback: formatYAxisLabel,
          maxTicksLimit: 5,
        },
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const labelIndex = context.dataIndex
            return values[labelIndex]
          },
        },
      },
    },
  }

  return (
    <div className="graph">
      <div className="primary-text">{title}</div>
      <Line data={data} options={options} />
    </div>
  )
}

export default LineChart
