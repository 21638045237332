import React from "react"
import { Img } from "react-image"
import Skeleton from "@mui/material/Skeleton"
import Tooltip from "@mui/material/Tooltip"
import { Button, CheckboxInput, IconDropdownMenu } from "../../Common/CommonElements"
import "./styles.scss"
import NoteIcon from "../../../assets/images/notes-added.svg"
import BookmarkIcon from "../../../assets/images/bookmark.svg"
import RemoveIcon from "../../../assets/images/remove.svg"
import BoardIcon from "../../../assets/images/board.svg"
import InstagramIcon from "../../../assets/images/card-instagram-profile.svg"
import YoutubeIcon from "../../../assets/images/card-youtube-profile.svg"
import TiktokIcon from "../../../assets/images/card-tiktok-profile.svg"
import MenuItem from "@mui/material/MenuItem"
import UpgradePopup from "../../Boards/UpgradePopup"
import LockIcon from "@mui/icons-material/Lock"

const InfluencerCard = ({ influencer, handleCardClick, handleCardSelect, selectedCards, setSelectedCards, handleAddCardsToBoard, showBoards, removeInfluencer, lockFeatures }) => {
  const handleBookmarkClick = () => {
    setSelectedCards([])
    handleAddCardsToBoard([influencer.id])
  }

  const platformIcons = {
    instagram: InstagramIcon,
    youtube: YoutubeIcon,
    tiktok: TiktokIcon,
  }

  return (
    <div className={`influencer-card ${influencer.type === "directs" ? "influencer-direct-match" : ""}`}>
      <div className="flex-container">
        <div className="image-container">
          <Img src={influencer.picture} className="image" loader={<Skeleton variant="rounded" width={150} height={150} />} />
          <div className="profile-button" onClick={() => window.open(influencer.profile, "_blank")}>
            <Tooltip
              title="Open profile"
              placement="top"
              arrow
              classes={{
                tooltip: "influencer-card-tooltip",
              }}>
              <img src={platformIcons[influencer.platform]} alt="" className="icon" />
            </Tooltip>
          </div>
        </div>
        <div className="info-container">
          <div>
            <div className="fullname">{influencer.fullname}</div>
            {influencer.username && (
              <a href={influencer.profile} target="_blank" className="username" rel="noreferrer">
                @{influencer.username}
              </a>
            )}
          </div>
          {lockFeatures && !influencer.cached ? (
            <Tooltip title={<UpgradePopup content="This feature is locked for you. Please choose a subscription plan" />} placement="top" arrow className="upgrade-popup-tooltip">
              <div>
                <Button type="button" label="View Insights" icon={<LockIcon />} theme={{ backgroundColor: "#94a4ff", color: "#ffffff", padding: "0.3rem 1rem", fontSize: "12px" }} />
              </div>
            </Tooltip>
          ) : (
            <Button
              type="button"
              label="View Insights"
              onClick={() => handleCardClick(influencer)}
              theme={{ backgroundColor: "#94a4ff", color: "#ffffff", padding: "0.3rem 1rem", fontSize: "12px" }}
            />
          )}
          {showBoards && influencer?.boards?.length > 0 && (
            <div className="boards-container">
              <div className="icon-container">
                <img src={BoardIcon} className="icon" alt="" />
              </div>
              <div className="boards">
                {influencer?.boards.map((board, index) => (
                  <div key={index} className="board">
                    {board.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="metrics-container">
        <div className="metrics me-2">
          <div className="number">{influencer.followers}</div>
          <div className="text">Followers</div>
        </div>
        <div className="metrics">
          <div className="number">{influencer.engagement_rate}</div>
          <div className="text">Engagement Rate</div>
        </div>
      </div>
      <div className="checkbox-container">
        <CheckboxInput value={selectedCards?.includes(influencer.id)} onChange={() => handleCardSelect(influencer.id)} color="#94a4ff" />
      </div>
      {influencer?.noted && (
        <div className="notes-icon">
          <div className="icon-container">
            <img src={NoteIcon} className="icon" alt="" />
          </div>
        </div>
      )}
      {!showBoards ? (
        <>
          <div className="bookmark-icon" onClick={handleBookmarkClick}>
            <div className="icon-container">
              <img src={BookmarkIcon} className="icon" alt="" />
            </div>
          </div>
        </>
      ) : (
        <div className="dropdown-container">
          <IconDropdownMenu>
            <MenuItem onClick={handleBookmarkClick}>
              <div className="mui-dropdown-menu-item-container">
                <img src={BookmarkIcon} alt="" className="dropdown-item-icon" />
                <div className="dropdown-item-text">Add to board</div>
              </div>
            </MenuItem>
            <MenuItem onClick={() => removeInfluencer(influencer.id)}>
              <div className="mui-dropdown-menu-item-container">
                <img src={RemoveIcon} alt="" className="dropdown-item-icon" />
                <div className="dropdown-item-text" style={{ color: "red" }}>
                  Remove Card
                </div>
              </div>
            </MenuItem>
          </IconDropdownMenu>
        </div>
      )}
    </div>
  )
}

export default InfluencerCard
