import React, { useState } from "react"
import { Tooltip } from "@mui/material"
import "./styles.scss"
import { TooltipActive, TooltipInactive } from "../../../assets/images"

const DefaultTooltip = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Tooltip
      title={content}
      onOpen={handleOpen}
      onClose={handleClose}
      open={isOpen}
      placement="top"
      classes={{
        tooltip: "Tooltip__default",
      }}
      arrow>
      {isOpen ? <img src={TooltipActive} alt="tooltip-active" style={{ marginTop: "2px" }} /> : <img src={TooltipInactive} alt="tooltip-inactive" style={{ marginTop: "2px" }} />}
    </Tooltip>
  )
}

export default DefaultTooltip
