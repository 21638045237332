import React, { useMemo } from "react"
import Select from "react-select"
import { initialize, FilterContainer, DropdownIndicator } from "./FilterUtils"

const RangeFilter = ({ name, label, tooltip, fromOptions, toOptions, state, setState, hideHeader }) => {
  const handleChange = (option, type) => {
    initialize(name, state, setState)
    setState((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [type]: option?.value || null,
      },
    }))
  }

  const filteredToOptions = useMemo(() => toOptions.filter((option) => !state[name]?.min || option.value > state[name].min), [state[name]?.min])
  const filteredFromOptions = useMemo(() => fromOptions.filter((option) => !state[name]?.max || option.value < state[name].max), [state[name]?.max])

  const selectedMinValue = fromOptions.find((option) => option.value === state[name]?.min) || null
  const selectedMaxValue = toOptions.find((option) => option.value === state[name]?.max) || null

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState} hideHeader={hideHeader}>
      <div className="flex-container">
        <div className="range-filter-container">
          <Select
            placeholder="Min"
            classNamePrefix="react-select"
            options={filteredFromOptions}
            value={selectedMinValue}
            onChange={(option) => handleChange(option, "min")}
            components={{ DropdownIndicator }}
          />
        </div>
        <div className="range-filter-container">
          <Select
            placeholder="Max"
            classNamePrefix="react-select"
            options={filteredToOptions}
            value={selectedMaxValue}
            onChange={(option) => handleChange(option, "max")}
            components={{ DropdownIndicator }}
          />
        </div>
      </div>
    </FilterContainer>
  )
}

export default RangeFilter
