import React, { useState, useCallback } from "react"
import debounce from "lodash.debounce"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"
import { initialize, FilterContainer, DropdownIndicator, Option, WeightSelector, SearchBarOption } from "./FilterUtils"

const SelectFilter = ({ name, label, tooltip, options, service, platform, isMulti, limit, weights, state, setState, placeholder, hideHeader, isAsync, isCreatable, isSearch, defaultWeight }) => {
  const [loadedOptions, setLoadedOptions] = useState()

  const loadOptions = useCallback(
    debounce((value, callback) => {
      service(value, platform).then((response) => {
        const options = response?.map((option) => ({
          label: option.name,
          value: option.id,
          picture: option.picture,
          followers: option.followers,
        }))
        setLoadedOptions(options)
        callback(options)
      })
    }, 300),
    [service, platform]
  )

  const handleChange = (response) => {
    initialize(name, state, setState)

    const addWeight = (item) => (defaultWeight ? { ...item, weight: defaultWeight } : item)

    const options = Array.isArray(response) ? response.map(addWeight) : addWeight(response)

    if (!limit || options.length <= limit) {
      setState((prev) => ({
        ...prev,
        [name]: options,
      }))
    }
  }

  const clearValue = (option) => {
    setState((prev) => ({
      ...prev,
      [name]: prev[name].filter((item) => item.value !== option.value),
    }))
  }

  const renderPlaceholder = typeof placeholder === "string" ? placeholder : placeholder?.[platform] || "Enter..."

  let SelectComponent
  if (isCreatable) {
    SelectComponent = AsyncCreatableSelect
  } else if (isAsync) {
    SelectComponent = AsyncSelect
  } else {
    SelectComponent = Select
  }

  const customComponents = {
    ...(isSearch ? { Option: SearchBarOption } : {}),
    ...(isMulti && !isSearch ? { Option } : {}),
    DropdownIndicator: isAsync || isCreatable ? null : DropdownIndicator,
  }

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState} hideHeader={hideHeader}>
      <div className="select-filter-container">
        <SelectComponent
          cacheOptions
          classNamePrefix="react-select"
          defaultOptions={loadedOptions}
          loadOptions={loadOptions}
          options={options}
          placeholder={renderPlaceholder}
          value={state[name] || null}
          onChange={handleChange}
          isMulti={isMulti}
          closeMenuOnSelect={!isCreatable}
          components={customComponents}
          onSelectResetsInput={false}
          onBlurResetsInput={false}
          formatCreateLabel={(input) => input}
          hideSelectedOptions={false}
          noOptionsMessage={() => null}
          isClearable={false}
        />
      </div>
      {weights && state[name] && (
        <>
          {isMulti ? (
            state[name].map((value) => <WeightSelector key={value.value} value={value} isMulti={true} name={name} weights={weights} state={state} setState={setState} clearValue={clearValue} />)
          ) : (
            <WeightSelector value={state[name]} name={name} weights={weights} state={state} setState={setState} />
          )}
        </>
      )}
    </FilterContainer>
  )
}

export default SelectFilter
