import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = ({ title, values }) => {
  const data = {
    labels: values.map((item) => item.key),
    datasets: [
      {
        data: values.map((item) => item.value),
        backgroundColor: ["#94A4FF", "#E78DEA", "#FF8266", "#FFA600", "#FF79B1"],
        hoverBackgroundColor: ["#8593E5", "#CD7DD0", "#E5755B", "#E59500", "#E56C9F"],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  }

  return (
    <div className="graph">
      <div className="primary-text">{title}</div>
      <div className="graph-flex-container">
        <div style={{ width: "180px", height: "180px" }}>
          <Doughnut data={data} options={options} />
        </div>
        <div className="legend">
          {values.map(({ key, value }, index) => (
            <div key={index} className="label">
              <div className="color" style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}></div>
              <div className="text">
                {key}: {value}%
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PieChart
