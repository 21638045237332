import React, { useState } from "react"
import PlaybookModal from "./components/PlaybookModal"
import "./styles.scss"
import { WhiteStars } from "../../../assets/images"

const Playbook = ({ username, setCurrentPlatform, influencerService, setInfluencerFilters, setAudienceFilters, submitFilters, clearFilters, setCurrentPlaybook }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
    clearFilters()
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const runPlaybookFilters = (playbook) => {
    setCurrentPlaybook(playbook)
    submitFilters()
  }

  return (
    <>
      <button component="div" className="Playbook__floating-action-button" onClick={openModal}>
        <img src={WhiteStars} alt="playbook-icon" className="Playbook__floating-action-button-icon" />
        <span className="Playbook__floating-action-button-text">Play Book</span>
      </button>
      <PlaybookModal
        username={username}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        setCurrentPlatform={setCurrentPlatform}
        influencerService={influencerService}
        setInfluencerFilters={setInfluencerFilters}
        setAudienceFilters={setAudienceFilters}
        runPlaybookFilters={runPlaybookFilters}
      />
    </>
  )
}

export default Playbook
