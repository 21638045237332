import React from "react"
import LineChart from "../Charts/LineChart"
import Metrics from "../Metrics"
import "./styles.scss"

const InfluencerStats = ({ stats, metrics }) => {
  return (
    <div className="graphs-container">
      {stats?.followers && (
        <div style={{ width: "48.5%" }}>
          <LineChart title="Followers Growth" labels={stats.followers.labels} values={stats.followers.values} />
        </div>
      )}
      {stats?.following && (
        <div style={{ width: "48.5%" }}>
          <LineChart title="Following Growth" labels={stats.following.labels} values={stats.following.values} />
        </div>
      )}
      {stats?.average_likes && (
        <div style={{ width: "48.5%" }}>
          <LineChart title="Average Likes" labels={stats.average_likes.labels} values={stats.average_likes.values} />
        </div>
      )}
      {stats?.average_comments && (
        <div style={{ width: "48.5%" }}>
          <LineChart title="Average Comments" labels={stats.average_comments.labels} values={stats.average_comments.values} />
        </div>
      )}
      {stats?.average_views && (
        <div style={{ width: "48.5%" }}>
          <LineChart title="Average Views" labels={stats.average_views.labels} values={stats.average_views.values} />
        </div>
      )}
      {stats?.average_shares && (
        <div style={{ width: "48.5%" }}>
          <LineChart title="Average Shares" labels={stats.average_shares.labels} values={stats.average_shares.values} />
        </div>
      )}
      {stats?.average_saves && (
        <div style={{ width: "48.5%" }}>
          <LineChart title="Average Saves" labels={stats.average_saves.labels} values={stats.average_saves.values} />
        </div>
      )}
      {stats?.average_posts && (
        <div style={{ width: "48.5%" }}>
          <LineChart title="Average Posts" labels={stats.average_posts.labels} values={stats.average_posts.values} />
        </div>
      )}
      {metrics?.interests?.length > 0 && (
        <div style={{ width: "100%" }}>
          <Metrics title="Interests" metrics={metrics.interests} />
        </div>
      )}
      {metrics?.brands?.length > 0 && (
        <div style={{ width: "100%" }}>
          <Metrics title="Brand Affinity" metrics={metrics.brands} />
        </div>
      )}
      {metrics?.hashtags?.length > 0 && (
        <div style={{ width: "100%" }}>
          <Metrics title="Hashtags" metrics={metrics.hashtags} />
        </div>
      )}
      {metrics?.mentions?.length > 0 && (
        <div style={{ width: "100%" }}>
          <Metrics title="Mentions" metrics={metrics.mentions} />
        </div>
      )}
    </div>
  )
}

export default InfluencerStats
