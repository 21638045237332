import React from "react"
import { initialize, FilterContainer } from "./FilterUtils"

const TextFilter = ({ name, label, tooltip, platform, placeholder, state, setState, hideHeader }) => {
  const handleChange = (event) => {
    initialize(name, state, setState)
    setState((prev) => ({
      ...prev,
      [name]: event.target.value,
    }))
  }

  const renderPlaceholder = typeof placeholder === "string" ? placeholder : placeholder?.[platform] || "Enter..."

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState} hideHeader={hideHeader}>
      <div className="text-filter-container">
        <input className="text-filter" placeholder={renderPlaceholder} value={state[name] || ""} onChange={handleChange} />
      </div>
    </FilterContainer>
  )
}

export default TextFilter
