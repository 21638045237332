import React, { useState } from "react"
import { Button as MuiButton, FormControl, TextField, InputLabel, OutlinedInput, InputAdornment, IconButton, MenuItem, Checkbox, FormControlLabel, Menu, FormHelperText } from "@mui/material"
import { LoadingButton as MuiLoadingButton } from "@mui/lab"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone"
import { darken } from "@mui/material/styles"
import "./styles.scss"

const Button = ({ type, label, onClick, theme, isDisabled, icon }) => {
  return (
    <MuiButton
      type={type}
      onClick={onClick}
      className="mui-button"
      disabled={isDisabled}
      startIcon={icon}
      sx={{
        backgroundColor: theme.backgroundColor,
        color: `${theme.color} !important`,
        padding: theme.padding,
        border: theme?.borderColor ? `1px solid ${theme.borderColor}` : `1px solid ${theme.backgroundColor}`,
        borderRadius: theme?.borderRadius ? theme.borderRadius : "",
        fontSize: theme.fontSize,
        width: theme?.width ? theme.width : "auto",
        "&:hover": {
          backgroundColor: darken(theme.backgroundColor, 0.1),
        },
        "&:disabled": {
          backgroundColor: "#d3d3d3",
          border: `1px solid #d3d3d3`,
          cursor: "not-allowed",
          pointerEvents: "auto",
        },
      }}>
      {label}
    </MuiButton>
  )
}

const LoadingButton = ({ type, label, onClick, isLoading, isDisabled, theme }) => (
  <MuiLoadingButton
    type={type}
    onClick={onClick}
    variant="contained"
    className="mui-button"
    disabled={isDisabled}
    loading={isLoading}
    sx={{
      backgroundColor: `${theme.backgroundColor} !important`,
      color: `${theme.color} !important`,
      padding: theme.padding,
      border: theme?.borderColor ? `1px solid ${theme.borderColor}` : `1px solid ${theme.backgroundColor}`,
      width: theme?.width ? theme.width : "auto",
      "&:hover": {
        backgroundColor: darken(theme.backgroundColor, 0.1),
      },
      ...(isDisabled && {
        "&:disabled": {
          backgroundColor: "#d3d3d3 !important",
          border: `1px solid #d3d3d3`,
          cursor: "not-allowed",
          pointerEvents: "auto",
        },
      }),
    }}>
    {label}
  </MuiLoadingButton>
)

const TextInput = ({ label, placeholder, name, value, onChange, error, isMulti }) => (
  <TextField
    placeholder={placeholder}
    name={name}
    label={label}
    value={value}
    onChange={onChange}
    size="small"
    fullWidth
    className="mui-form-field"
    error={error ?? false}
    helperText={error}
    multiline={isMulti}
    {...(isMulti && { maxRows: 5 })}
  />
)

const PasswordInput = ({ label, name, value, onChange, error }) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <FormControl className="mui-form-field" fullWidth size="small" error={Boolean(error)}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

const CheckboxInput = ({ label, name, value, onChange, color }) => (
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        className="mui-form-field"
        name={name}
        checked={value}
        onChange={onChange}
        sx={{
          color: color,
          "&.Mui-checked": {
            color: color,
            opacity: "1 !important",
          },
        }}
      />
    }
    label={label}
    sx={{ color: color }}
  />
)

const SelectInput = ({ label, placeholder, name, value, options, onChange, error, isDisabled, boardsSelect, columnSelect }) => (
  <TextField
    disabled={isDisabled}
    select
    placeholder={placeholder}
    name={name}
    label={label}
    value={value}
    onChange={onChange}
    size="small"
    fullWidth
    className="mui-form-field"
    error={error ?? false}
    helperText={error}>
    {options?.length === 0 ? (
      boardsSelect ? (
        <MenuItem value={0}>Default Board</MenuItem>
      ) : columnSelect ? (
        <MenuItem value={0}>Default Column</MenuItem>
      ) : (
        <MenuItem disabled>No options</MenuItem>
      )
    ) : (
      options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))
    )}
  </TextField>
)

const DropdownMenu = ({ label, theme, children }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MuiButton
        className="mui-dropdown-button"
        variant="contained"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          backgroundColor: theme.backgroundColor,
          border: theme.border,
          borderRadius: theme.borderRadius,
          fontSize: theme.fontSize,
          "&:hover": {
            backgroundColor: darken(theme.backgroundColor, 0.1),
          },
        }}>
        {label}
      </MuiButton>
      <Menu className="mui-dropdown-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onClick: (event) => {
              if (child.props.onClick) {
                child.props.onClick(event)
              }
              handleClose()
            },
          })
        )}
      </Menu>
    </>
  )
}

const IconDropdownMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MuiButton className="mui-icon-dropdown-button" onClick={handleClick}>
        <MoreHorizTwoToneIcon sx={{ color: "black", fontSize: "1.5rem" }} />
      </MuiButton>
      <Menu className="mui-icon-dropdown-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onClick: (event) => {
              if (child.props.onClick) {
                child.props.onClick(event)
              }
              handleClose()
            },
          })
        )}
      </Menu>
    </>
  )
}

export { Button, LoadingButton, TextInput, PasswordInput, CheckboxInput, SelectInput, DropdownMenu, IconDropdownMenu }
